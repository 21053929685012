<template>
  <div style="height: calc(100vh - 88px)" class="grey lighten-2">
    <v-container class="grey lighten-4 py-1" fluid>
      <v-row align="center">
        <v-col cols="12" class="text-h6 font-weight-light">
          STRIPPING
          <v-divider />
        </v-col>
      </v-row>
    </v-container>

    <v-container class="grey lighten-4" style="height: 94px" fluid>
      <!-- attrs: {{ attrs }} -->
      <v-row align="center">
        <v-col cols="4">
          <v-text-field :label="tLabel('Rif. pratica, codice esterno, unit number')" clearable v-model="searchValue" :title="tLabel('Ricerca per ordine, unit number')" @input="(val) => (searchValue = val ? val.toUpperCase() : null)" />
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" :title="tLabel('Search')" @click="searchOrders"><v-icon>mdi-magnify</v-icon></v-btn>
        </v-col>
        <v-col cols="4" class="text-right">
          <v-btn v-if="!isHistory" color="primary" :title="tLabel('Crea ordine di stripping')" @click="goToCreateOrder">
            <v-icon class="pr-2">mdi-plus</v-icon>{{ tLabel("Crea ordine") }}
          </v-btn>
          {{ isHistory ? "" : "&nbsp;" }}
          <v-btn
            v-if="!isHistory"
            outlined
            color="primary"
            :title="tLabel('Vai a storico ordini')"
            @click="
              isHistory = true;
              searchOrders();
            "
            ><v-icon>mdi-history</v-icon></v-btn
          >
          <v-btn
            v-else
            outlined
            color="primary"
            :title="tLabel('Vai a ordini in yard')"
            @click="
              isHistory = false;
              searchOrders();
            "
            ><v-icon>mdi-swap-horizontal</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-container>

    <!-- {{ orders }} -->

    <div v-if="!isHistory" style="height: calc(100vh - 227px)" class="scroller">
      <StrippingOrderCard v-for="order in orders" :key="order.id" :order="order" :backAttrs="{ isHistory: isHistory }" :ref="`order_${order.id}`" />
    </div>

    <div v-else style="height: calc(100vh - 227px)" class="pa-2">
      <v-data-table
        show-expand
        single-expand
        :expanded.sync="expanded"
        @item-expanded="onItemExpanded"
        :items="orders"
        :headers="headers"
        :items-per-page="50"
        :options.sync="tableOptions"
        :server-items-length="totalRecords"
        :footer-props="{
          itemsPerPageText: 'Risultati per pagina',
          itemsPerPageOptions: [50, 100, 250, 1000],
          showFirstLastPage: true,
        }"
        class="elevation-2 rounded-0"
        height="calc(100vh - 303px)"
        fixed-header
      >
        <template v-slot:item.actions="{ item }">
          <v-btn outlined small color="error" :title="tLabel('Elimina ordine di stripping')" @click="onClickDeleteOrder(item)">
            <v-icon color="error" small>mdi-delete</v-icon>
          </v-btn>
          &nbsp;
          <v-btn outlined small color="primary" :title="tLabel('Modifica ordine di stripping')" @click="goToEditOrder(item.id)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.details="{ item }">
          <div v-if="!item.details || item.details === null"></div>
          <div v-else-if="item.details.split(/\r\n|\r|\n/).length > 3" :title="item.details">
            <div v-for="i in 3" :key="i">
              {{ item.details.split(/\r\n|\r|\n/)[i - 1] }}
            </div>
            ...
          </div>
          <div v-else>
            <div v-for="det in item.details.split(/\r\n|\r|\n/)" :key="det">
              {{ det }}
            </div>
          </div>
        </template>
        <template v-slot:item.remarks="{ item }">
          <div v-if="!item.remarks || item.remarks === null"></div>
          <div v-if="item.remarks.split(/\r\n|\r|\n/).length > 3" :title="item.remarks">
            <div v-for="i in 3" :key="i">
              {{ item.remarks.split(/\r\n|\r|\n/)[i - 1] }}
            </div>
            ...
          </div>
          <div v-else>
            <div v-for="rem in item.remarks.split(/\r\n|\r|\n/)" :key="rem">
              {{ rem }}
            </div>
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="grey lighten-4">
            <v-container v-if="item.operations && item.operations.length > 0" class="grey lighten-4 px-0" fluid>
              <v-row align="center" v-for="op in item.operations" :key="op.id">
                <v-col cols="12">
                  <v-hover v-slot="{ hover }" open-delay="50">
                    <v-card :elevation="hover ? 4 : 2" :color="hover ? 'grey lighten-5' : null" rounded="0" @click="goToOperationDetail(op.id)">
                      <v-card-text>
                        <v-row align="start">
                          <v-col cols="6">
                            <OrderOpUnitInfoCard :unit="op.unit" v-if="op.unit && op.unit !== null" />
                          </v-col>
                          <v-col cols="6">
                            <div v-if="op.goods && op.goods !== null && op.goods.length > 0">
                              <OrderOpGoodsInfoCard :goods="goo" v-for="goo in op.goods" :key="goo.visitId" />
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid class="pa-2 ma-2" v-else>
              <v-row align="center">
                <v-col cols="12">
                  {{ tLabel("Nessuna operazione.") }}
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>
      <!-- <div v-for="so in orders" :key="so.id" class="white my-3">
        {{ so }}
      </div> -->
    </div>
    <v-dialog v-model="deleteOrderDialogVisible" max-width="600">
      <v-card v-if="orderToDelete !== null">
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Elimina ordine") }}</v-card-title>
        <v-card-text class="text-h6 py-2"> Eliminare l'ordine di stripping {{ orderToDelete.reference }}/{{ orderToDelete.extCode }} ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="deleteOrderDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="deleteOrder"><v-icon>mdi-delete</v-icon>{{ tLabel("Elimina") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormatMixins from "../../../mixins/FormatsMixins";
import ToastMixin from "../../../mixins/ToastMixin";
import StrippingOrderCard from "@/components/yard/StrippingOrderCard";
import OrderOpUnitInfoCard from "@/components/yard/OrderOpUnitInfoCard";
import OrderOpGoodsInfoCard from "@/components/yard/OrderOpGoodsInfoCard";

export default {
  name: "Stripping",
  data() {
    return {
      isHistory: false,
      searchValue: null,
      orders: [],
      headers: [
        { text: "", value: "data-table-expand", class: "blue lighten-5" },
        { text: "", value: "actions", class: "blue lighten-5" },
        { text: "Rif. pratica", value: "reference", class: "text-subtitle-1 text-uppercase blue lighten-5" },
        { text: "Codice esterno", value: "extCode", class: "text-subtitle-1 text-uppercase blue lighten-5" },
        { text: "Cliente", value: "company", class: "text-subtitle-1 text-uppercase blue lighten-5" },
        { text: "Dettagli", value: "details", class: "text-subtitle-1 text-uppercase blue lighten-5" },
        { text: "Note", value: "remarks", class: "text-subtitle-1 text-uppercase blue lighten-5" },
      ],
      totalRecords: 0,
      tableOptions: null,
      expanded: [],
      deleteOrderDialogVisible: false,
      orderToDelete: null,
    };
  },
  props: {
    attrs: {
      type: Object,
      default: null,
    },
  },
  mixins: [ToastMixin, FormatMixins],
  components: { StrippingOrderCard, OrderOpUnitInfoCard, OrderOpGoodsInfoCard },
  async mounted() {
    if (this.attrs) {
      this.isHistory = this.attrs.isHistory;
    }
    await this.searchOrders();
  },
  updated() {
    this.getFocus();
  },
  watch: {
    tableOptions: {
      async handler() {
        await this.searchOrders();
      },
      deep: true,
    },
  },
  methods: {
    getFocus() {      
      if (this.attrs && this.attrs !== null) {   
        let orderFromId = null;
        if (this.attrs.operationFromId) {
          orderFromId = this.attrs.operationFromId.split("-")[0];
        } else if (this.attrs.orderFromId) {
          orderFromId = this.attrs.orderFromId;
        }       
        if (orderFromId !== null) {
          this.$refs["order_" + orderFromId][0].$el.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    goToCreateOrder() {
      this.$router.push({
        name: "CreateStrippingOrder",
      });
    },
    goToEditOrder(orderId) {
      this.$router.push({
        name: "EditStrippingOrder",
        params: {
          orderId: orderId,
          attrs: {
            isHistory: this.isHistory,
          },
        },
      });
    },
    goToOperationDetail(operationId) {
      this.$router.push({
        name: "StrippingOperation",
        params: {
          operationId: operationId,
          attrs: {
            isHistory: this.isHistory,
          },
        },
      });
    },
    async onItemExpanded(event) {
      if (event.value && event.item.operations.length === 0) {
        var order = await this.$api.yardData.getOrder("stripping", event.item.id);
        event.item.operations = order.operations;
      }
    },
    onClickDeleteOrder(order) {
      this.orderToDelete = order;
      this.deleteOrderDialogVisible = true;
    },
    deleteOrder() {
      console.log("TODO delete order", this.orderToDelete);
      this.showSuccess("TODO Ordine eliminato correttamente.");
      this.deleteOrderDialogVisible = false;
    },
    async searchOrders() {
      var so = [];
      if (!this.isHistory) {
        so = await this.$api.yardData.getOrders("stripping", false, this.searchValue);
      } else if (this.tableOptions !== null) {
        so = await this.$api.yardData.getOrders(
          "stripping",
          true,
          this.searchValue,
          this.tableOptions.itemsPerPage,
          this.tableOptions.page,
          this.tableOptions.sortBy.length > 0 ? this.tableOptions.sortBy[0] : null,
          this.tableOptions.sortDesc.length > 0 ? (this.tableOptions.sortDesc[0] ? "1" : "0") : null
        );
        this.totalRecords = so.length;
      }
      this.orders = so.reverse();
      // console.log("orders ---->", this.orders);
    },
  },
};
</script>

<style scoped>
.scroller {
  overflow-y: auto;
  scrollbar-width: thin;
}

.scroller::-webkit-scrollbar {
  width: 6px;
}

.scroller::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroller::-webkit-scrollbar-thumb {
  background: #888;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
